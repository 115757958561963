import React from 'react'
import {isTranslationKey, loginErrorQueryParam, type TranslationKey} from 'notability-services-common-universal'
import {ComponentWithGalleryContext} from '../util/GalleryContext'
import {useCustomRouter} from '../util/useCustomRouter'

import {GetServerSideProps} from 'next'
import {getStrings} from '../util/localizationUtils'
import {initializeDatabaseConnectionIfNeeded} from '../setup'
import {sspWithGalleryContext} from '../util/GalleryContextSSR'
import dynamic from 'next/dynamic'
import {redirectAuthenticatedUser} from '../util/redirect'
import {LoginLayout} from '../components/LoginLayout'
interface LoginProps {
  errorKey: TranslationKey | null
}

export default ComponentWithGalleryContext(Login)

const LoginForm = dynamic(() => import('../components/LoginForm'), {
  ssr: false
})

function Login({errorKey}: LoginProps) {
  const router = useCustomRouter()
  return (
    <LoginLayout pageTitle={'pageTitleLogin'}>
      <LoginForm onLoggedIn={() => router.pushLoginComplete()} errorKey={errorKey ?? undefined} />
    </LoginLayout>
  )
}

export const getServerSideProps: GetServerSideProps<LoginProps> = sspWithGalleryContext(async (context) => {
  await initializeDatabaseConnectionIfNeeded()
  const errorKey = isTranslationKey(context.query[loginErrorQueryParam]) ? context.query[loginErrorQueryParam] : null

  const redirect = await redirectAuthenticatedUser(context)
  if (redirect) {
    return {redirect}
  }

  return {
    props: {
      ...(await getStrings(context)),
      errorKey: errorKey || null
    }
  }
})
